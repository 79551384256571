import { InferenceQuality } from "../../types";
import { SessionInferenceMode } from "../graphql/schema.graphql";
import { FormType2D } from "./forms/2D/types";
import { FormType3D } from "./forms/3D/constants";
import { FormTypeRealtime } from "./forms/Realtime/constants";
import { FormTypeUpscale } from "./forms/Upscale/constants";
import { FormTypeVideo } from "./forms/Video/constants";

export const SAVE_VALUES_TO_SERVER_DEBOUNCE_MS = 2000;

export const INFERENCE_FORMS_WIDTH = 240;

export const SESSION_HEADER_HEIGHT = 48;

export const MIN_SEED = 1;
export const MAX_SEED = 1099511627776;
export const DEFAULT_DENSITY = 1;
export const MIN_DENSITY = 1;
export const MAX_DENSITY = 5;
export const MIN_STYLE_WEIGHT = 0;
export const MAX_STYLE_WEIGHT = 100;
export const MIN_REFERENCE_SIMILARITY = 0;
export const MAX_REFERENCE_SIMILARITY = 100;
export const DEFAULT_REFERENCE_SIMILARITY = 50;
export const MIN_DENOISING_STEPS = 1;
export const MAX_DENOISING_STEPS = 50;
export const MIN_BASE_SHIFT = 0;
export const MAX_BASE_SHIFT = 12;
export const MIN_MAX_SHIFT = 0;
export const MAX_MAX_SHIFT = 12;
export const MIN_GUIDANCE_SCALE = 2;
export const MAX_GUIDANCE_SCALE = 25;
export const MIN_FACE_LIMIT = 200;
export const MAX_FACE_LIMIT = 1000000; // 1 million
export const DEFAULT_FACE_LIMIT = 10000; // 10k

export type InferenceFormsContexts = "canvas" | "session";

export type PerformanceType = InferenceQuality;
export const PERFORMANCE_OPTIONS = ["LOW", "MEDIUM", "HIGH"] as const;

export const PerformanceOptionToNameMap = new Map<PerformanceType, string>([
  ["LOW", "Fast, Low Quality"],
  ["MEDIUM", "Balanced Quality and Speed"],
  ["HIGH", "Slower, Best Quality"],
]);

// For passing data through routes in new inference form
export type InferenceFormNavigationState = {
  mode: SessionInferenceMode;
  form?:
    | Partial<FormTypeRealtime>
    | Partial<FormType2D>
    | Partial<FormTypeVideo>
    | Partial<FormType3D>
    | Partial<FormTypeUpscale>;
};

export type FormType =
  | FormTypeRealtime
  | FormType2D
  | FormTypeVideo
  | FormType3D
  | FormTypeUpscale;
